<template>
  <h2>Admin Material page</h2>
</template>

<script>
export default {

}
</script>

<style>

</style>